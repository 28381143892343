body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.third-party-logos {
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
  min-height: 28px;
  max-height: 28px !important;
  margin: 5px auto;
}
.kt-footer{
  width: 100%;
  bottom: 0;
  padding: 0;
  border-top: 1px solid #c2bdba;
  margin-top: 50px;

}
.kt-footer p {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.navbar-light .navbar-nav  a {
  padding: 1.15rem 1.65rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  font-family: Poppins, Helvetica, sans-serif;
  display: inline-block;
  font-weight: normal;
  color: #212529 !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media only screen and (max-width: 600px) {
  .kt-footer p {
    height: 30px;
    display: block !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    font-size: 16px;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-hide {
    display: none !important;
  }
  .third-party-logos {
    margin: 20px auto !important;
  }
  .footer-row {
    display: inline-grid;
}
}
.collapse:not(.show){
  display: block;
  text-align: left;
}
.bg-light {
  background-color: #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
}
.MuiPaper-rounded {
  border-radius: 15px !important;
}
.navbar{
  padding: unset !important;
}
.navbar-nav .nav-link {
  padding: 1rem 1.65rem !important;
}
.navbar-brand {
  padding: unset !important;
}
.mobile-show {
  display: none;
}
.mobile-hide {
  display: show;
}
.default-outline-btn {
  background-color: #ffffff;
  border: 1px solid #D3D3D3;
  color: #D3D3D3;
}