.App {
    text-align: center;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.headerBtn {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding: 1.15rem 1.65rem;
    font-size: 1.05rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    display: inline-block;
    font-weight: normal;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    margin: auto 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.headerBtn:hover {
    color: #212529;
    text-decoration: none;
}

.headerImage {
    max-height: 100px;
    max-width: 150px;
    margin-top: 6px;
    margin-left: 20px;
}


.footer {
    padding: 20px 0px;
    background: #ffffff;
    background-color: rgb(255, 255, 255);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    border-top: 1px solid #c2bdba;
    margin-top: 40px;
}

.container {
    max-width: 1140px;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.kt-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0 1.25rem 0 0;
    padding-top: 0px;
    font-size: 1rem;
    font-weight: 400;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    color: #74788d;
}

.third-party-logos {
    max-width: 100%;
    /*height: 100%;*/
    vertical-align: middle;
    min-height: 28px;
    /*max-height: 44px !important;*/
    margin: 5px auto;
}


.text-center {
    text-align: center !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
